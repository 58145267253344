import axios from "axios";
import { baseUrlOtodisUploadAPI } from "../config/ApiUrlConfig";

class UploadDocumentService {
  axios = axios.create({
    baseURL: baseUrlOtodisUploadAPI,
  });

  axiosCdn = axios.create({
    baseURL: "https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1",
  });


  async upload(formData) {
    try {
      return await this.axios.post("/upload/document/service-bureau/order", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async extractBpkb(formData) {
    try {
      return await this.axiosCdn.post("/bpkb/extract/file", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async uploadBpkb(formData) {
    try {
      const response = await this.axiosCdn.post("/bpkb/upload/amarta", formData);

      if (response.data.success === false) {
        throw new Error(response.data.message || 'Unknown error from server');
      }

      return response.data;
    } catch (e) {
      if (e.response) {
        const { data } = e.response;

        const errorMessage = data?.message || 'Unknown error from server';
        throw new Error(errorMessage);
      } else {
        throw new Error(e.message || 'Network error, please try again.');
      }
    }
  }



  async extractNoticeNew(formData) {
    try {
      return await this.axios.post("/extract/document/notice/new", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }


  async extractNoticeJabar(formData) {
    try {
      return await this.axios.post("/extract/document/notice", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }


  async extractNoticeSulawesi(formData) {
    try {
      return await this.axios.post("/extract/document/notice/sulawesi", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async uploadNotice(formData) {
    try {
      return await this.axios.post("/upload/document/service-bureau/order/notice", formData);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }
}

export const uploadDocumentService = new UploadDocumentService();
